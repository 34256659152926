import * as React from 'react'
import * as css from './Hero.module.css'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Video from 'components/common/Video'
import CTALink from 'components/common/CTALink'
import nl2br from 'utils/nl2br'
import SanityBlock from 'components/common/SanityBlock'

const Hero = React.forwardRef(({ title, _rawDescription, link, video, location }, ref) => {
  const { slogan } = useStaticQuery(query).sanitySite

  return (
    <section className="h-screen bg-suede-black text-white" ref={ref}>
      <div className="absolute inset-0 h-full">
        <Video
          {...video}
          className="absolute inset-0 w-full h-full bg-transparent object-cover anim-appear"
          playsInline autoPlay muted loop preloadPoster
        />

        {!!location && (
          <p className="location-caption z-[1] <md:hidden">
            <span className="action">{location}</span>
          </p>
        )}
      </div>

      <div className={css.overlay} />

      <div className={`${css.inner} ctnr padding-x`}>
        <div>
          {title && (
            <h1 className={`${css.h1} <md:!text-3xl text-shadow`}>{nl2br(title)}</h1>
          )}
          <SanityBlock className="mt-[1em] mb-[2em] text-2xl <md:text-xl max-w-[750px]" body={_rawDescription} />
          <CTALink {...link} />
        </div>

        {slogan && (
          <div className={`${css.slogan} technical`}>
            <p>{slogan}</p>
          </div>
        )}

        <aside className={`${css.scroll} technical`}>
          <Link className={css.txt} to="#our-expertise">Scroll</Link>
        </aside>
      </div>
    </section>
  )
})

export default Hero

const query = graphql`query HomeHero {
  sanitySite {
    slogan
  }
}`
